<template>
  <div class="index">
    <div class="indexHd">
      <a
        href="/list?id=620&navName=专题专栏&groupName=学习宣传贯彻党的二十届三中全会精神"
        target="_blank"
        ><img
          class="esdImg"
          src="../assets/img/temp/img_esd0005.png"
          alt=""
          style=""
      /></a>
      <a
        href="/list?id=487&navName=专题专栏&groupName=学习贯彻习近平新时代中国特色社会主义思想主题教育"
        target="_blank"
        ><img
          class="indexHdImg"
          src="../assets/img/temp/zt_xxxjpjs3.png"
          alt=""
          style="width: 100%;"
      /></a>
      <a
        :href="`/list?id=426&navName=专题专栏&groupName=“三抓三促”行动进行时`"
        target="_blank"
        ><img
          class="indexHdImg"
          src="../assets/img/temp/zt_szsc.jpg"
          alt=""
          style=""
      /></a>
      <!-- <a
        href="/list?id=188&navName=专题专栏&groupName=勠力同心%20战胜疫情"
        target="_blank"
        >
        <img class="indexHdImg" src="../assets/img/banner-top.jpg" alt="" style="width: 100%;"
      /></a> -->
    </div>
    <div class="container_wrap">
      <Header />

      <div class="index_container">
        <!-- 要闻 -->
        <div class="home_pre clearfloat">
          <div class="home_pre_l fl"><span>要闻头条</span></div>
          <div class="inner">
            <el-carousel
              height="58px"
              direction="vertical"
              :interval="2500"
              indicator-position="none"
            >
              <el-carousel-item v-for="item in newsTop" :key="item.id">
                <p class="single-line">
                  <a
                    v-if="!item.is_link || item.is_link === 0"
                    class="intro-btn"
                    target="_blank"
                    :href="
                      `/detail?id=${item.id}&navName=新闻中心&groupName=集团动态`
                    "
                    >{{ item.title }}</a
                  >
                  <a
                    v-if="item.is_link === 1"
                    target="_blank"
                    class="intro-btn"
                    :href="item.link_url"
                    >{{ item.title }}</a
                  >
                </p>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>

        <div class="sec01">
          <el-row :gutter="15">
            <el-col :span="10">
              <div class="sec01_l_t">
                <el-carousel height="320px">
                  <el-carousel-item v-for="item in sec01LImg" :key="item.id">
                    <div class="sec01_l_t_w">
                      <a
                        v-if="!item.is_link || item.is_link === 0"
                        target="_blank"
                        :href="
                          `/detail?id=${item.id}&navName=新闻中心&groupName=集团动态`
                        "
                      >
                        <img :src="item.img" alt="" />
                        <p>{{ item.title }}</p>
                      </a>
                      <a
                        v-if="item.is_link === 1"
                        target="_blank"
                        :href="item.link_url"
                      >
                        <img :src="item.img" alt="" />
                        <p>{{ item.title }}</p>
                      </a>
                    </div>
                    <h3 class="small">{{ item }}</h3>
                  </el-carousel-item>
                </el-carousel>

                <div class="sec01_l_t_b">
                  <el-row :gutter="12">
                    <el-col class="sec01_l_t_b_img" :span="8">
                      <a
                        target="_blank"
                        href="/detail?id=1&navName=公司概况&groupName=集团概况&noList=1"
                        ><img src="../assets/img/sec01_l_m_img01.png" alt=""
                      /></a>
                    </el-col>
                    <el-col class="sec01_l_t_b_img" :span="8">
                      <a href="/member"
                        ><img src="../assets/img/sec01_l_m_img02.png" alt=""
                      /></a>
                    </el-col>
                    <el-col class="sec01_l_t_b_img" :span="8">
                      <a href="/list?id=2&navName=公司概况&groupName=组织机构"
                        ><img src="../assets/img/sec01_l_m_img03.png" alt=""
                      /></a>
                    </el-col>
                    <el-col class="sec01_l_t_b_img" :span="8">
                      <a href="/list?id=5&navName=公司概况&groupName=大事记"
                        ><img src="../assets/img/sec01_l_m_img04.png" alt=""
                      /></a>
                    </el-col>
                    <el-col class="sec01_l_t_b_img" :span="8">
                      <a href="/list?id=3&navName=公司概况&groupName=公司荣誉"
                        ><img src="../assets/img/sec01_l_m_img05.png" alt=""
                      /></a>
                    </el-col>
                    <el-col class="sec01_l_t_b_img" :span="8">
                      <a href="/list?id=26&navName=学习园地&groupName=学习园地"
                        ><img src="../assets/img/sec01_l_m_img06.png" alt=""
                      /></a>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="sec_notic clearfloat">
                <div class="sec_notic_l fl">
                  <a href="/list?id=9&navName=新闻中心&groupName=公示公告"
                    ><span>公示公告</span></a
                  >
                </div>
                <div class="sec_notic_inner">
                  <ul class="news_list news_list01">
                    <li v-for="(item, index) in newsGsgg" :key="index">
                      <p class="news_list_inner single-line">
                        <a
                          v-if="!item.is_link || item.is_link === 0"
                          target="_blank"
                          :href="
                            `/detail?id=${item.id}&navName=新闻中心&groupName=公示公告`
                          "
                          >{{ item.title }}</a
                        >
                        <a
                          v-if="item.is_link === 1"
                          target="_blank"
                          :href="item.link_url"
                          >{{ item.title }}</a
                        >
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </el-col>
            <el-col :span="14">
              <div class="sec_news_w">
                <div class="sec_news_tit">
                  <span
                    ><a href="/list?id=7&navName=新闻中心&groupName=集团动态"
                      >集团动态</a
                    ></span
                  >
                </div>
                <div class="sec_news_g">
                  <ul class="news_list news_list02">
                    <li
                      v-for="(item, index) in newsJtdt"
                      :key="index"
                      :class="
                        dayjs(item.create_time).format('YYYY-MM-DD') ==
                        dayjs().format('YYYY-MM-DD')
                          ? 'news_new'
                          : ''
                      "
                    >
                      <div class="news_list_time fr">
                        {{ dayjs(item.create_time).format("YYYY-MM-DD HH:mm") }}
                      </div>
                      <p class="news_list_inner">
                        <a
                          v-if="!item.is_link || item.is_link === 0"
                          target="_blank"
                          :href="
                            `/detail?id=${item.id}&navName=新闻中心&groupName=集团动态`
                          "
                          >{{ item.title }}</a
                        >
                        <a
                          v-if="item.is_link === 1"
                          target="_blank"
                          :href="item.link_url"
                          >{{ item.title }}</a
                        >
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="sec_news_w" style="margin-top: 10px;">
                <div class="sec_news_tit">
                  <span
                    ><a
                      href="/list?id=8&navName=新闻中心&groupName=子%28分%29公司动态"
                      >子(分)公司动态</a
                    ></span
                  >
                </div>
                <div class="sec_news_g">
                  <ul class="news_list news_list02">
                    <li
                      v-for="(item, index) in newsFgsdt"
                      :key="index"
                      :class="
                        dayjs(item.create_time).format('YYYY-MM-DD') ==
                        dayjs().format('YYYY-MM-DD')
                          ? 'news_new'
                          : ''
                      "
                    >
                      <div class="news_list_time fr">
                        {{ dayjs(item.create_time).format("YYYY-MM-DD HH:mm") }}
                      </div>
                      <p class="news_list_inner">
                        <a
                          v-if="!item.is_link || item.is_link === 0"
                          target="_blank"
                          :href="
                            `/detail?id=${item.id}&navName=新闻中心&groupName=子(分)公司动态`
                          "
                          >{{ item.title }}</a
                        >
                        <a
                          v-if="item.is_link === 1"
                          target="_blank"
                          :href="item.link_url"
                          >{{ item.title }}</a
                        >
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <div class="sec_notic clearfloat" style="margin: 20px 0px;">
                <div class="sec_notic_l fl sec_notic_l_zt">
                  <a><span>专题链接</span></a>
                </div>
                <div class="sec_notic_inner">
                  <ul class="news_list news_list01">
                    <li v-for="(item, index) in ztSrc" :key="index">
                      <p class="news_list_inner single-line">
                        <a :href="`${item.url}`" target="_blank">{{
                          item.name
                        }}</a>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>

        <div class="sec02">
          <div class="sec_hd">
            <img src="../assets/img/ghlyw_05.jpg" alt="" />
          </div>
          <el-row :gutter="8" class="index_sec_jtyw">
            <el-col :span="4">
              <a href="/list?id=14&navName=集团业务&groupName=公路"
                ><img src="../assets/img/yw_03.jpg" alt=""
              /></a>
            </el-col>
            <el-col :span="4">
              <a href="/list?id=15&navName=集团业务&groupName=航空"
                ><img src="../assets/img/yw_05.jpg" alt=""
              /></a>
            </el-col>
            <el-col :span="4">
              <a href="/list?id=16&navName=集团业务&groupName=旅游"
                ><img src="../assets/img/yw_09.jpg" alt=""
              /></a>
            </el-col>
            <el-col :span="4">
              <a href="/list?id=17&navName=集团业务&groupName=金融"
                ><img src="../assets/img/yw_11.jpg" alt=""
              /></a>
            </el-col>
            <el-col :span="4">
              <!-- <a href="/list?id=18&navName=集团业务&groupName=保险"><img src="../assets/img/yw_07.jpg" alt=""></a> -->
              <a href="http://www.ypic.cn/" target="_blank"
                ><img src="../assets/img/yw_07.jpg" alt=""
              /></a>
            </el-col>
            <el-col :span="4">
              <a href="/list?id=19&navName=集团业务&groupName=融资"
                ><img src="../assets/img/yw_13.jpg" alt=""
              /></a>
            </el-col>
          </el-row>
        </div>

        <el-row class="index_d_img" style="margin-top: 15px;">
          <el-col :span="24">
            <img src="../assets/img/img_d_01.png" alt="" />
            <img src="../assets/img/img_d_03.jpg" alt="" />
          </el-col>
        </el-row>

        <el-row
          class="sec03"
          :gutter="10"
          style="margin-bottom: 15px; margin-top: 30px;"
        >
          <el-col :span="7">
            <div class="sec_wrap">
              <div class="sec_tit">
                <span><a href="/special">专题专栏</a></span>
              </div>
              <div class="sec_wrap_c sec_zl">
                <div class="swiper-container">
                  <div class="swiper-wrapper">
                    <div
                      class="swiper-slide"
                      v-for="(item, index) in newsZtZl"
                      :key="index"
                    >
                      <a
                        v-if="!item.is_link || item.is_link === 0"
                        :href="
                          `/list?id=${item.id}&navName=专题专栏&groupName=${item.name}`
                        "
                        :title="item.name"
                        ><img :src="item.img" alt=""
                      /></a>
                      <a
                        v-if="item.is_link === 1"
                        target="_blank"
                        :href="item.link_url"
                        :title="item.name"
                        ><img :src="item.img" alt=""
                      /></a>
                    </div>
                  </div>
                  <!-- 如果需要分页器 -->
                  <!-- <div class="swiper-pagination"></div> -->

                  <!-- 如果需要导航按钮 -->
                  <!-- <div class="swiper-button-prev"></div>
                  <div class="swiper-button-next"></div> -->

                  <!-- 如果需要滚动条 -->
                  <!-- <div class="swiper-scrollbar"></div> -->
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="sec_wrap">
              <div class="sec_tit">
                <span
                  ><a href="/list?id=171&navName=党建之窗&groupName=党建工作"
                    >党建工作</a
                  ></span
                >
              </div>
              <div class="sec_wrap_c">
                <ul class="news_list news_list01">
                  <li v-for="(item, index) in newsDjgz" :key="index">
                    <p class="news_list_inner single-line">
                      <a
                        v-if="!item.is_link || item.is_link === 0"
                        target="_blank"
                        :href="
                          `/detail?id=${item.id}&navName=党建之窗&groupName=党建工作`
                        "
                        >{{ item.title }}</a
                      >
                      <a
                        v-if="item.is_link === 1"
                        target="_blank"
                        :href="item.link_url"
                        >{{ item.title }}</a
                      >
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="sec_wrap">
              <div class="sec_tit">
                <span><a href="/discipline">纪检监察</a></span>
              </div>
              <div class="sec_wrap_c">
                <ul class="news_list news_list01">
                  <li v-for="(item, index) in newsJjjc" :key="index">
                    <p class="news_list_inner single-line">
                      <a
                        v-if="!item.is_link || item.is_link === 0"
                        target="_blank"
                        :href="
                          `/detail?id=${item.id}&navName=党建之窗&groupName=纪检监察`
                        "
                        >{{ item.title }}</a
                      >
                      <a
                        v-if="item.is_link === 1"
                        target="_blank"
                        :href="item.link_url"
                        >{{ item.title }}</a
                      >
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </el-col>
          <el-col :span="7">
            <div class="sec_wrap">
              <div class="sec_tit">
                <span><a href="/list?id=526&navName=假冒国企&groupName=假冒国企">假冒国企</a></span>
              </div>
              <div class="sec_wrap_c">
                <ul class="news_list news_list01">
                  <li>
                    <p class="news_list_inner single-line">
                      <a target="_blank" :href="`/searchCompany`"
                        >公航旅国有企业“白名单”查询</a
                      >
                    </p>
                  </li>
                  <li v-for="(item, index) in newsJmgq" :key="index">
                    <p class="news_list_inner single-line">
                      <a
                        v-if="!item.is_link || item.is_link === 0"
                        target="_blank"
                        :href="
                          `/detail?id=${item.id}&navName=假冒国企&groupName=假冒国企`
                        "
                        >{{ item.title }}</a
                      >
                      <a
                        v-if="item.is_link === 1"
                        target="_blank"
                        :href="item.link_url"
                        >{{ item.title }}</a
                      >
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </el-col>

        </el-row>

        <el-row class="sec_jtdt" :gutter="10">
          <el-col span="4-8">
            <div class="sec_wrap">
              <div class="sec_tit">
                <span
                  ><a href="/list?id=26&navName=学习园地&groupName=学习园地"
                    >学习园地</a
                  ></span
                >
              </div>
              <ul class="news_list news_list01">
                <li v-for="(item, index) in newsXxyd" :key="index">
                  <p class="news_list_inner single-line">
                    <a
                      v-if="!item.is_link || item.is_link === 0"
                      target="_blank"
                      :href="
                        `/detail?id=${item.id}&navName=学习园地&groupName=学习园地`
                      "
                      >{{ item.title }}</a
                    >
                    <a
                      v-if="item.is_link === 1"
                      target="_blank"
                      :href="item.link_url"
                      >{{ item.title }}</a
                    >
                  </p>
                </li>
              </ul>
            </div>
          </el-col>
          <el-col span="4-8">
            <div class="sec_wrap">
              <div class="sec_tit">
                <span
                  ><a href="/list?id=10&navName=新闻中心&groupName=政策法规"
                    >政策法规</a
                  ></span
                >
              </div>
              <ul class="news_list news_list01">
                <li v-for="(item, index) in newsZcfg" :key="index">
                  <p class="news_list_inner single-line">
                    <a
                      v-if="!item.is_link || item.is_link === 0"
                      target="_blank"
                      :href="
                        `/detail?id=${item.id}&navName=政策法规&groupName=政策法规`
                      "
                      >{{ item.title }}</a
                    >
                    <a
                      v-if="item.is_link === 1"
                      target="_blank"
                      :href="item.link_url"
                      >{{ item.title }}</a
                    >
                  </p>
                </li>
              </ul>
            </div>
          </el-col>
          <el-col span="4-8">
            <div class="sec_wrap">
              <div class="sec_tit">
                <span
                  ><a href="/list?id=13&navName=新闻中心&groupName=行业动态"
                    >行业动态</a
                  ></span
                >
              </div>
              <ul class="news_list news_list01">
                <li v-for="(item, index) in newsHydt" :key="index">
                  <p class="news_list_inner single-line">
                    <a
                      v-if="!item.is_link || item.is_link === 0"
                      target="_blank"
                      :href="
                        `/detail?id=${item.id}&navName=新闻中心&groupName=行业动态`
                      "
                      >{{ item.title }}</a
                    >
                    <a
                      v-if="item.is_link === 1"
                      target="_blank"
                      :href="item.link_url"
                      >{{ item.title }}</a
                    >
                  </p>
                </li>
              </ul>
            </div>
          </el-col>
          <el-col span="4-8">
            <div class="sec_wrap">
              <div class="sec_tit">
                <span
                  ><a href="/list?id=11&navName=新闻中心&groupName=媒体聚焦"
                    >媒体聚焦</a
                  ></span
                >
              </div>
              <ul class="news_list news_list01">
                <li v-for="(item, index) in newsMtjj" :key="index">
                  <p class="news_list_inner single-line">
                    <a
                      v-if="!item.is_link || item.is_link === 0"
                      target="_blank"
                      :href="
                        `/detail?id=${item.id}&navName=新闻中心&groupName=媒体聚焦`
                      "
                      >{{ item.title }}</a
                    >
                    <a
                      v-if="item.is_link === 1"
                      target="_blank"
                      :href="item.link_url"
                      >{{ item.title }}</a
                    >
                  </p>
                </li>
              </ul>
            </div>
          </el-col>
          <el-col span="4-8">
            <div class="sec_wrap">
              <div class="sec_tit">
                <span
                  ><a href="/list?id=566&navName=党建之窗&groupName=中心组学习"
                    >中心组学习</a
                  ></span
                >
              </div>
              <ul class="news_list news_list01">
                <li v-for="(item, index) in newsZxzxx" :key="index">
                  <p class="news_list_inner single-line">
                    <a
                      v-if="!item.is_link || item.is_link === 0"
                      target="_blank"
                      :href="
                        `/detail?id=${item.id}&navName=党建之窗&groupName=中心组学习`
                      "
                      >{{ item.title }}</a
                    >
                    <a
                      v-if="item.is_link === 1"
                      target="_blank"
                      :href="item.link_url"
                      >{{ item.title }}</a
                    >
                  </p>
                </li>
              </ul>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <div class="sec_wrap">
              <div class="sec_tit">
                <span
                  ><a href="/list?id=7&navName=新闻中心&groupName=集团动态"
                    >集团动态</a
                  ></span
                >
              </div>
              <div class="sec_scroll_jtdt sec_scroll">
                <vue-seamless-scroll
                  :data="newsJtdtScroll"
                  :class-option="classOption"
                  class="table-content"
                >
                  <ul class="item_list clearfloat">
                    <div v-for="(item, index) in newsJtdtScroll" :key="index">
                      <li v-if="item.img">
                        <a
                          v-if="!item.is_link || item.is_link === 0"
                          target="_blank"
                          :href="
                            `/detail?id=${item.id}&navName=新闻中心&groupName=集团动态`
                          "
                        >
                          <div class="pic"><img :src="item.img" alt="" /></div>
                        </a>
                        <a
                          v-if="item.is_link === 1"
                          target="_blank"
                          :href="item.link_url"
                        >
                          <div class="pic"><img :src="item.img" alt="" /></div>
                        </a>
                      </li>
                    </div>
                  </ul>
                </vue-seamless-scroll>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <div class="sec_wrap">
              <div class="sec_tit">
                <span
                  ><a href="/list?id=12&navName=新闻中心&groupName=视频集锦"
                    >视频集锦</a
                  ></span
                >
              </div>
              <div class="sec_scroll_jtdt sec_scroll">
                <vue-seamless-scroll
                  :data="scrollList"
                  :class-option="classOption"
                  class="table-content"
                >
                  <ul class="item_list clearfloat">
                    <li v-for="(item, index) in scrollList" :key="index">
                      <a
                        v-if="!item.is_link || item.is_link === 0"
                        target="_blank"
                        :href="
                          `/detail?id=${item.id}&navName=新闻中心&groupName=视频集锦`
                        "
                      >
                        <div class="pic"><img :src="item.img" alt="" /></div>
                      </a>
                      <a
                        v-if="item.is_link === 1"
                        target="_blank"
                        :href="item.link_url"
                      >
                        <div class="pic"><img :src="item.img" alt="" /></div>
                      </a>
                    </li>
                  </ul>
                </vue-seamless-scroll>
              </div>
            </div>
          </el-col>
        </el-row>

        <!-- 子分公司 -->
        <!-- <div class="sec_zfgs" style="margin-top: 20px;">
          <div class="sec_hd">
            <img src="../assets/img/ghfgs_09.jpg" alt="" />
          </div>
          <el-row class="sec_zfgs_list" :gutter="6">
            <el-col :span="6" v-for="(item, index) in listZfgs" :key="index">
              <a class="single-line" :href="item.url" target="_blank">{{
                item.name
              }}</a>
            </el-col>
          </el-row>
        </div> -->

        <!-- 友=友情链接 -->
        <div class="sec_link">
          <ul class="sec_link_l clearfloat">
            <li>
              <a href="http://www.mot.gov.cn/" target="_blank"
                ><img src="../assets/img/link01.gif" alt=""
              /></a>
            </li>
            <li>
              <a href="http://www.gansu.gov.cn/" target="_blank"
                ><img src="../assets/img/link_zggs.jpg" alt=""
              /></a>
            </li>
            <li>
              <a href="https://gzw.gansu.gov.cn/" target="_blank"
                ><img src="../assets/img/link_gssgzw.jpg" alt=""
              /></a>
            </li>
            <li>
              <a href="http://jtys.gansu.gov.cn" target="_blank"
                ><img src="../assets/img/link030.gif" alt=""
              /></a>
            </li>
            <li>
              <a href="http://www.zgjtb.com/" target="_blank"
                ><img src="../assets/img/link05.gif" alt=""
              /></a>
            </li>
            <li>
              <a href="https://www.ndrc.gov.cn/" target="_blank"
                ><img src="../assets/img/link_f_img01.gif" alt=""
              /></a>
            </li>
            <li>
              <a href="http://www.pbc.gov.cn/" target="_blank"
                ><img src="../assets/img/link_f_img02.gif" alt=""
              /></a>
            </li>
            <li>
              <a href="https://www.nfra.gov.cn/cn/view/pages/index/index.html" target="_blank"
                ><img src="../assets/img/link_gjjrjdglj.jpg" alt=""
              /></a>
            </li>
            <li>
              <a href="http://www.csrc.gov.cn/" target="_blank"
                ><img src="../assets/img/link_f_img04.gif" alt=""
              /></a>
            </li>
            <li>
              <a href="http://www.nafmii.org.cn/" target="_blank"
                ><img src="../assets/img/link_f_img05.gif" alt=""
              /></a>
            </li>
          </ul>
          <ul class="sec_link_l clearfloat">
            <li>
              <el-select v-model="value01" placeholder="请选择">
                <el-option
                  v-for="item in options01"
                  :key="item.name"
                  :label="item.name"
                  :value="item.url"
                >
                  <a :href="item.url" target="_blank" style="display: block;">{{
                    item.name
                  }}</a>
                </el-option>
              </el-select>
            </li>
            <li>
              <el-select v-model="value02" placeholder="请选择">
                <el-option
                  v-for="item in options02"
                  :key="item.name"
                  :label="item.name"
                  :value="item.url"
                >
                  <a :href="item.url" target="_blank" style="display: block;">{{
                    item.name
                  }}</a>
                </el-option>
              </el-select>
            </li>
            <li>
              <el-select v-model="value03" placeholder="请选择">
                <el-option
                  v-for="item in options03"
                  :key="item.name"
                  :label="item.name"
                  :value="item.url"
                >
                  <a :href="item.url" target="_blank" style="display: block;">{{
                    item.name
                  }}</a>
                </el-option>
              </el-select>
            </li>
            <li>
              <el-select v-model="value04" placeholder="请选择">
                <el-option
                  v-for="item in options04"
                  :key="item.name"
                  :label="item.name"
                  :value="item.url"
                >
                  <a :href="item.url" target="_blank" style="display: block;">{{
                    item.name
                  }}</a>
                </el-option>
              </el-select>
            </li>
            <li>
              <el-select v-model="value05" placeholder="请选择">
                <el-option
                  v-for="item in options05"
                  :key="item.name"
                  :label="item.name"
                  :value="item.url"
                >
                  <a :href="item.url" target="_blank" style="display: block;">{{
                    item.name
                  }}</a>
                </el-option>
              </el-select>
            </li>
          </ul>
        </div>
      </div>

      <Footer />
    </div>

    <!-- 悬浮框 -->
    <!-- <div class="tan_right">
      <a href="http://www.gscq.com.cn/zt/hg2020/gsgh-27/index.html" target="_blank"><img src="../assets/img/tan_r_img01.png" alt=""></a>
      <a href="http://www.gscq.com.cn/index.php?s=neplatform&amp;c=category&amp;id=140" target="_blank"><img src="../assets/img/tan_r_img02.png" alt=""></a>
      <a href="http://gscq.com.cn/zt/2020/" target="_blank"><img src="../assets/img/tan_r_img03.png" alt=""></a>
      <a href="http://www.gscq.com.cn/zt/hg/" target="_blank"><img src="../assets/img/tan_r_img04.jpg" alt=""></a>
      <a href="/jyxc" target="_blank"><img src="../assets/img/tan_r_img05.png" alt=""></a>
    </div> -->

    <!-- 悬浮框-公航旅集团所属企业查询窗口 -->
    <div class="tanQuery">
      <div class="tanQueryW tanQueryW03">
        <a href="https://ghatg.hcmde.com/" target="_blank">
          <img :src="imgQuery03" alt="">
        </a>
        <p>培训基地</p>
      </div>
      <div class="tanQueryW">
        <a href="/list?id=630&navName=新闻中心&groupName=严正声明" target="_blank" title="严正声明">
          <img :src="imgQuery02" alt="">
        </a>
        <p>严正声明</p>
      </div>
      <div class="tanQueryW">
        <a href="/searchCompany" target="_blank" title="公航旅国有企业“白名单”查询">
          <img :src="imgQuery01" alt="">
        </a>
        <p>公航旅国有企业<br>“白名单”查询</p>
      </div>
    </div>
    
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import Swiper from "swiper";
import vueSeamlessScroll from "vue-seamless-scroll";
// import 'swiper/swiper.scss';

export default {
  name: "index",
  components: {
    Header,
    vueSeamlessScroll,
    Footer
    // Swiper
  },
  data() {
    return {
      imgQuery01: require("../assets/img/ico_query05.png"),
      imgQuery02: require("../assets/img/ico_query06.png"),
      imgQuery03: require("../assets/img/ico_query10.jpg"),
      // imgQuery: require("../assets/img/ico_query02.png"),
      newsTop: [],
      sec01LImg: [],
      newsJtdt: [],
      newsFgsdt: [],
      newsGsgg: [],
      newsZtZl: [
        {
          id: 620,
          name: '学习宣传贯彻党的二十届三中全会精神',
          img: require("../assets/img/temp/zz_zt_esjszqh.jpg"),
          is_link: 0,
          link_url: ""
        },
        {
          id: 570,
          name: '党纪学习教育',
          img: require("../assets/img/temp/zt_djxxjy.png"),
          is_link: 1,
          link_url: "/partyLearn"
        },
        {
          id: 569,
          name: '普法宣传',
          img: require("../assets/img/temp/zt_pfxc.jpg"),
          is_link: 0,
          link_url: ""
        },
        {
          id: 487,
          name: '学习贯彻习近平新时代中国特色社会主义思想主题教育',
          img: require("../assets/img/temp/zt_xjpjs.png"),
          is_link: 0,
          link_url: ""
        },
        {
          id: 426,
          name: '“三抓三促”行动进行时',
          img: require("../assets/img/temp/zt_szsc_x_001.jpg"),
          is_link: 0,
          link_url: ""
        },
        {
          id: 619,
          name: '“反电诈行动”集中宣传月活动',
          img: require("../assets/img/temp/zt_fdzhd.jpg"),
          is_link: 0,
          link_url: ""
        },
        {
          id: 373,
          name: '学习宣传贯彻中国共产党第二十次全国代表大会精神',
          img: require("../assets/img/img_dj_esd.png"),
          is_link: 0,
          link_url: ""
        },
        {
          id: 271,
          name: "档案工作",
          img: require("../assets/img/zt_dagz.jpg"),
          is_link: 1,
          link_url: "/files"
        },
        {
          id: 270,
          name: "党建融入生产经营典型创建活动",
          img: require("../assets/img/zz_zt_djhd.png"),
          is_link: 0,
          link_url: ""
        },
        {
          id: 259,
          name: "奋进新征程建功新时代",
          img: require("../assets/img/zz_zt_fjxsd.jpg"),
          is_link: 0,
          link_url: ""
        },
        {
          id: 181,
          name: "学习宣传贯彻党的十九大精神",
          img: require("../assets/img/zz_zt_img01.png"),
          is_link: 0,
          link_url: ""
        },
        {
          id: 182,
          name: "学习宣传贯彻党的十九届四中全会精神",
          img: require("../assets/img/zz_zt_img02.png"),
          is_link: 0,
          link_url: ""
        },
        {
          id: 183,
          name: "学习宣传贯彻党的十九届五中全会精神",
          img: require("../assets/img/zz_zt_img03.png"),
          is_link: 0,
          link_url: ""
        },
        {
          id: 184,
          name: "学习宣传贯彻党的十九届六中全会精神",
          img: require("../assets/img/zz_zt_img04.png"),
          is_link: 0,
          link_url: ""
        },
        {
          id: 185,
          name: "“不忘初心、牢记使命”主题教育",
          img: require("../assets/img/zz_zt_img05.png"),
          is_link: 0,
          link_url: ""
        },
        {
          id: 186,
          name: "党史学习教育",
          img: require("../assets/img/zz_zt_img06.png"),
          is_link: 1,
          link_url: "/partyNews"
        },
        {
          id: 507,
          name: '公航旅集团《信访工作条例》落实年',
          img: require("../assets/img/temp/zt_xfgzlsn.jpg"),
          is_link: 1,
          link_url: "/petition"
        },
        {
          id: 187,
          name: "精神文明建设",
          img: require("../assets/img/zz_zt_img07.png"),
          is_link: 0,
          link_url: ""
        },
        {
          id: 188,
          name: "勠力同心 战胜疫情",
          img: require("../assets/img/zz_zt_img08.png"),
          is_link: 0,
          link_url: ""
        },
        {
          id: 189,
          name: "众志成城 防汛救灾",
          img: require("../assets/img/zz_zt_img09.png"),
          is_link: 0,
          link_url: ""
        },
        {
          id: 190,
          name: "脱贫攻坚帮扶工作",
          img: require("../assets/img/zz_zt_img10.png"),
          is_link: 0,
          link_url: ""
        },
        {
          id: 191,
          name: "壮丽七十年，逐梦新时代",
          img: require("../assets/img/zz_zt_img11.png"),
          is_link: 0,
          link_url: ""
        },
        {
          id: 192,
          name: "保密法律法规",
          img: require("../assets/img/zz_zt_img12.png"),
          is_link: 0,
          link_url: ""
        },
        {
          id: 193,
          name: "职工电子书屋",
          img: require("../assets/img/zz_zt_img13.png"),
          is_link: 1,
          link_url: "https://wk3.bookan.com.cn/?id=22195&token=&productId=5!#/"
        }
      ],
      newsDjgz: [],
      newsJjjc: [],
      newsXxyd: [],
      newsZcfg: [],
      newsHydt: [],
      newsMtjj: [],
      newsZxzxx: [],
      listZfgs: [],
      scrollList: [],
      newsJtdtScroll: [],
      newsJmgq: [],
      ztSrc: [
        {
          name: "甘肃省公路航空旅游投资集团有限公司所属企业引入战略投资者",
          url: "http://www.gscq.com.cn/index.php?s=xm&c=show&id=6656"
        },
        {
          name: "甘肃省属国有企业2020年混合所有制改革-项目推介",
          url: "http://www.gscq.com.cn/zt/hg2020/gsgh-27/index.html"
        },
        {
          name: "甘肃省属国有企业2020年资源资产出让-项目专题推介 ",
          url: "http://www.gscq.com.cn/index.php?s=neplatform&c=category&id=140"
        },
        {
          name: "甘肃省属国有企业2020年资源资产出让-项目推介",
          url: "http://gscq.com.cn/zt/2020/"
        }
      ],
      classOption: {
        limitMoveNum: 2,
        direction: 2
      },
      options01: [
        {
          name: "外交部",
          url: "http://www.fmprc.gov.cn"
        },
        {
          name: "国防部",
          url: "http://www.mod.gov.cn/"
        },
        {
          name: "发展改革委",
          url: "http://www.ndrc.gov.cn/"
        },
        {
          name: "教育部",
          url: "http://www.moe.edu.cn/"
        },
        {
          name: "科技部",
          url: "http://www.most.gov.cn/"
        },
        {
          name: "中纪委",
          url: "https://www.ccdi.gov.cn/"
        },
        {
          name: "工业和信息化部",
          url: "http://www.miit.gov.cn/"
        },
        {
          name: "自然资源部",
          url: "http://www.mnr.gov.cn/"
        },
        {
          name: "公安部",
          url: "http://www.mps.gov.cn/"
        },
        {
          name: "民政部",
          url: "http://www.mca.gov.cn"
        },
        {
          name: "司法部",
          url: "http://www.moj.gov.cn"
        },
        {
          name: "财政部",
          url: "http://www.mof.gov.cn"
        },
        {
          name: "人力资源社会保障部",
          url: "http://www.mohrss.gov.cn"
        },
        {
          name: "生态环境部",
          url: "http://www.mee.gov.cn"
        },
        {
          name: "住房城乡建设部",
          url: "http://www.mohurd.gov.cn"
        },
        {
          name: "交通运输部",
          url: "http://www.mot.gov.cn"
        },
        {
          name: "水利部",
          url: "http://www.mwr.gov.cn/"
        },
        {
          name: "农业农村部",
          url: "http://www.moa.gov.cn/"
        },
        {
          name: "商务部",
          url: "http://www.mofcom.gov.cn"
        },
        {
          name: "文化旅游部",
          url: "http://www.mct.gov.cn/"
        },
        {
          name: "国家卫生健康委员会",
          url: "http://www.nhc.gov.cn"
        },
        {
          name: "应急管理部",
          url: "http://www.chinasafety.gov.cn/"
        },
        {
          name: "人民银行",
          url: "http://www.pbc.gov.cn"
        },
        {
          name: "审计署",
          url: "http://www.audit.gov.cn/"
        },
        {
          name: "国资委",
          url: "http://www.sasac.gov.cn/index.html"
        },
        {
          name: "海关总署",
          url: "http://www.customs.gov.cn/publish/portal0/"
        },
        {
          name: "税务总局",
          url: "http://www.chinatax.gov.cn/"
        },
        {
          name: "国家市场监督管理总局",
          url: "http://www.aqsiq.gov.cn/"
        },
        {
          name: "国家广播电视总局",
          url: "http://www.sapprft.gov.cn/"
        },
        {
          name: "体育总局",
          url: "http://www.sport.gov.cn/"
        },
        {
          name: "统计局",
          url: "http://www.stats.gov.cn/"
        },
        {
          name: "知识产权局",
          url: "http://www.sipo.gov.cn/"
        },
        {
          name: "宗教局",
          url: "http://www.sara.gov.cn/"
        },
        {
          name: "参事室",
          url: "http://www.counsellor.gov.cn/"
        },
        {
          name: "国管局",
          url: "http://www.ggj.gov.cn/"
        },
        {
          name: "版权局",
          url: "http://www.ncac.gov.cn/"
        },
        {
          name: "法制办",
          url: "http://www.chinalaw.gov.cn/"
        },
        {
          name: "国务院研究室",
          url: "http://www.gov.cn/gjjg/2005-12/26/content_137261.htm"
        },
        {
          name: "台办",
          url: "http://www.gwytb.gov.cn/"
        },
        {
          name: "新闻办",
          url: "http://www.scio.gov.cn/"
        },
        {
          name: "新华社",
          url: "http://www3.xinhuanet.com/"
        },
        {
          name: "中科院",
          url: "http://www.cas.cn/"
        },
        {
          name: "社科院",
          url: "http://cass.cssn.cn/"
        },
        {
          name: "工程院",
          url: "http://www.cae.cn/"
        },
        {
          name: "发展研究中心",
          url: "http://www.drc.gov.cn/"
        },
        {
          name: "中央党校",
          url: "http://www.nsa.gov.cn/"
        },
        {
          name: "气象局",
          url: "http://www.cma.gov.cn/"
        },
        {
          name: "地震局",
          url: "http://www.cea.gov.cn/"
        },
        {
          name: "证监会",
          url: "http://www.csrc.gov.cn/pub/newsite/"
        }
      ],
      options02: [
        {
          name: "省国资委",
          url: "http://gzw.gansu.gov.cn/"
        },
        {
          name: "省发展改革委",
          url: "http://fzgg.gansu.gov.cn/"
        },
        {
          name: "省教育厅",
          url: "http://jyt.gansu.gov.cn/"
        },
        {
          name: "省科技厅",
          url: "http://kjt.gansu.gov.cn/"
        },
        {
          name: "省工信厅",
          url: "http://gxt.gansu.gov.cn/"
        },
        {
          name: "省政府国资委",
          url: "http://gzw.gansu.gov.cn/"
        },
        {
          name: "省纪委",
          url: "http://www.gsjw.gov.cn/"
        },
        {
          name: "省民族事务委员会",
          url: "http://mzsw.gansu.gov.cn/"
        },
        {
          name: "省公安厅",
          url: "http://gat.gansu.gov.cn/"
        },
        {
          name: "省民政厅",
          url: "http://mzt.gansu.gov.cn/"
        },
        {
          name: "省司法厅",
          url: "http://sft.gansu.gov.cn/"
        },
        {
          name: "省财政厅",
          url: "http://czt.gansu.gov.cn/"
        },
        {
          name: "省人社厅",
          url: "http://rst.gansu.gov.cn/"
        },
        {
          name: "省自然资源厅",
          url: "http://zrzy.gansu.gov.cn/"
        },
        {
          name: "省生态环境厅",
          url: "http://sthj.gansu.gov.cn/"
        },
        {
          name: "省住建厅",
          url: "http://zjt.gansu.gov.cn/"
        },
        {
          name: "省交通运输厅",
          url: "http://jtys.gansu.gov.cn/"
        },
        {
          name: "省水利厅",
          url: "http://slt.gansu.gov.cn/"
        },
        {
          name: "省农业农村厅",
          url: "http://nync.gansu.gov.cn/"
        },
        {
          name: "省商务厅",
          url: "http://swt.gansu.gov.cn/"
        },
        {
          name: "省文旅厅",
          url: "http://wlt.gansu.gov.cn/"
        },
        {
          name: "省卫生健康委",
          url: "http://wsjk.gansu.gov.cn/"
        },
        {
          name: "省退役军人厅",
          url: "http://tyjr.gansu.gov.cn/"
        },
        {
          name: "省应急厅",
          url: "http://yjgl.gansu.gov.cn/"
        },
        {
          name: "省审计厅",
          url: "http://sjt.gansu.gov.cn/"
        },
        {
          name: "省政府外事办",
          url: "http://wsb.gansu.gov.cn/"
        }
      ],
      options03: [
        {
          name: "金川集团有限公司",
          url: "http://www.jnmc.com/"
        },
        {
          name: "酒泉钢铁集团",
          url: "http://www.jiugang.com/"
        },
        {
          name: "白银有色集团股份有限公司",
          url: "http://www.bynmc.com/"
        },
        {
          name: "甘肃建投",
          url: "http://www.gsjtw.cc/"
        },
        {
          name: "甘肃省公路航空旅游投资集团有限公司",
          url: "http://www.ghatg.com/"
        }
      ],
      options04: [
        {
          name: "浙江省交通投资集团有限公司",
          url: "http://www.cncico.com/"
        },
        {
          name: "江苏交通控股有限公司",
          url: "http://www.jchc.cn"
        },
        {
          name: "广西交通投资集团",
          url: "http://www.gx.xinhuanet.com/wq/gxjt/"
        },
        {
          name: "广东省交通投资集团",
          url: "http://www.gdcg.com.cn"
        },
        {
          name: "山东高速集团有限公司",
          url: "http://www.sdhsg.com"
        },
        {
          name: "河北建设投资集团",
          url: "http://www.hecic.com.cn"
        },
        {
          name: "江西省投资集团公司",
          url: "http://www.jxic.com/"
        }
      ],
      options05: [
        {
          name: "国家开发银行",
          url: "http://www.cdb.com.cn"
        },
        {
          name: "中国进出口银行",
          url: "http://www.eximbank.gov.cn"
        },
        {
          name: "中国农业发展银行",
          url: "http://www.adbc.com.cn"
        },
        {
          name: "中国工商银行",
          url: "http://www.icbc.com.cn"
        },
        {
          name: "中国农业银行",
          url: "http://www.abchina.com"
        },
        {
          name: "中国银行",
          url: "http://www.bank-of-china.com"
        },
        {
          name: "中国建设银行",
          url: "http://www.ccb.com/cn/home/indexv3.html"
        },
        {
          name: "交通银行",
          url: "http://www.bankcomm.com"
        },
        {
          name: "招商银行",
          url: "http://www.cmbchina.com"
        },
        {
          name: "信托网",
          url: "http://www.trust-one.com"
        }
      ],
      value01: "国家部委网站",
      value02: "省直部门网站",
      value03: "省属国企网站",
      value04: "国内同行网站",
      value05: "金融机构网站"
    };
  },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 60) {
        return value.slice(0, 60) + "...";
      }
      return value;
    }
  },
  created: function() {
    // var _this = this
    // _this.initSwiper()
  },
  mounted() {
    var _this = this;

    _this.initSwiper();
    _this.getImprotNews();
    _this.getImgNews();
    _this.getJtdtNews();
    _this.getFgsdtNews();
    _this.getGsggNews();
    // _this.getZtzlNews();
    _this.getDjgzNews();
    _this.getJjjcNews();
    _this.getXxydNews();
    _this.getZcfgNews();
    _this.getHydtNews();
    _this.getMtjjNews();
    _this.getZxzxxNews();
    _this.getScrollList();
    _this.getlistZfgs();
    _this.getJtdtNewsScroll();
    _this.getNewsJmgq();
  },
  methods: {
    async getNewsJmgq() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 526 }
      );
      _this.newsJmgq = data.data.list.slice(0, 4);
    },
    initSwiper() {
      var mySwiper = new Swiper(".swiper-container", {
        autoplay: 2000,
        speed: 300,
        direction: "vertical",
        loop: true,
        slidesPerView: 2,
        spaceBetween: 10,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplayDisableOnInteraction: false //用户操作swiper之后重新启动autoplay
      });
      console.log("mySwiper :>> ", mySwiper);
    },

    async getImprotNews() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getImportNews"
      );
      _this.newsTop = data.data.list;
    },
    async getImgNews() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + "/news/getImgNews");
      _this.sec01LImg = data.data.list.slice(0, 5);
    },
    async getJtdtNews() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, pageSize: 10, group_id: 7 }
      );
      _this.newsJtdt = data.data.list.slice(0, 9);
    },
    async getFgsdtNews() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 8 }
      );
      _this.newsFgsdt = data.data.list.slice(0, 9);
    },
    async getGsggNews() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 9 }
      );
      _this.newsGsgg = data.data.list.slice(0, 3);
    },
    async getZtzlNews() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/group/getGrouplistByNavId",
        { id: 20210202 }
      );
      _this.newsZtZl = data.data;
      _this.$nextTick(() => {
        // 下一个UI帧再初始化swiper
        this.initSwiper();
      });
    },
    async getDjgzNews() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 171 }
      );
      _this.newsDjgz = data.data.list.slice(0, 5);
    },
    async getJjjcNews() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 172 }
      );
      _this.newsJjjc = data.data.list.slice(0, 5);
    },
    async getXxydNews() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 26 }
      );
      _this.newsXxyd = data.data.list.slice(0, 6);
    },
    async getZcfgNews() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 10 }
      );
      _this.newsZcfg = data.data.list.slice(0, 6);
    },
    async getHydtNews() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 13 }
      );
      _this.newsHydt = data.data.list.slice(0, 6);
    },
    async getMtjjNews() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 11 }
      );
      _this.newsMtjj = data.data.list.slice(0, 6);
    },
    async getZxzxxNews() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        { page: 1, group_id: 566 }
      );
      _this.newsZxzxx = data.data.list.slice(0, 6);
    },
    async getScrollList() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsListImg",
        { page: 1, group_id: 12 }
      );
      _this.scrollList = data.data.list;
    },
    async getlistZfgs() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/company/getCompanyList"
      );
      _this.listZfgs = data.data;
    },
    async getJtdtNewsScroll() {
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsListImg",
        { page: 1, pageSize: 60, group_id: 7 }
      );
      _this.newsJtdtScroll = data.data.list;
    }
  }
};
</script>
<style>
.index {
  /* min-width: 1020px; */
  min-width: 1200px;
  /* height: 800px; */
  /* padding-top: 365px; */
  padding-top: 60px;
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: 100% 1100px;
  /* background-image: url('../assets/img/banner_index_2.png'); */
  background-image: url("../assets/img/bg_01.jpg");
}
.index_container {
  padding: 10px;
}
.indexHd {
  width: 1200px;
  margin: 0 auto;
}
.indexHdImg {
  width: 100%;
  height: 116px;
}
.tanQuery {
  position: fixed;
  width: 120px;
  right: 10px;
  bottom: 5%;
  z-index: 11;
  text-align: center;
}
.tanQueryW {
  margin-bottom: 12px;
}
.tanQuery a {
  display: block;
  height: 120px;
  overflow: hidden;
  /* border-radius: 50%; */
  box-shadow: 0 3px 6px 2px rgba(0,0,0,0.1);
  background-color: #fff;
}
.tanQuery img {
  width: 100%;
  height: 100%;
}
.tanQuery p {
  line-height: 18px;
  margin-top: 6px;
  font-weight: bold;
  color: #1970b9;
}
.el-col-4-8 {
  width: 20%;
}
.sec_jtdt .news_list {
  min-height: 180px;
}
.tanQueryW03 a {
  height: auto;
}
.tanQueryW03 img {
  width: 100%;
  height: auto;
}
</style>
